import classNames from 'classnames';
import { useState } from 'react';
import { Input } from '../Input';

import { ReactComponent as EyeIcon } from './assets/EyeIcon.svg';
import { ReactComponent as EyeClosedIcon } from './assets/EyeClosedIcon.svg';

import styles from './PasswordInput.module.scss';

export const PasswordInput = ({ className, setInputValue, inputClassName, label, control }) => {
  const [showPass, setShowPass] = useState();

  let print_label = label ?? 'Пароль'

  const onShowClick = () => {
    setShowPass(state => !state);
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Input 
        {...control}  
        label={ print_label } 
        type={!showPass ? 'password' : 'text'} 
        setInputValue={setInputValue} 
        dynamicLabel 
        inputClassName={inputClassName}
      />
      {showPass ? (
        <EyeClosedIcon className={styles.icon} onClick={onShowClick} />
      ) : (
        <EyeIcon className={styles.icon} onClick={onShowClick} />
      )}
    </div>
  );
};
