import {
    ACTION_SET_BALANCE,
    ACTION_SET_COMISSION,
    ACTION_SET_FUNDS,
    ACTION_SET_BALANCE_WALLET,
    ACTION_SET_PROFIT_NOW,
    ACTION_SET_BALANCE_INVESTMENT,
    ACTION_SET_COMISSION_SUMM_NOT_PAYMENT,
    ACTION_SET_STAT_USER,
} from '../actions/FundAction'

const initialState = {
    comission: null,
    balance: null, // balance_wallet + balance_investment + profit_now
    balance_wallet: null, // Сумма на кошельке, которую можно еще инвестировать или выводить
    balance_profit_now: null, // Прибыль по активным фондам
    balance_investment: null, // Инвестированная сумма
    comission_summ_not_payment: null, // Накопленная, но ещё не выплаченная комиссия
    funds: null,
    stat: {
        profit_24: null,
        profit_week: null,
        profit_month: null,
        profit_year: null,
    },
};


const fundReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_BALANCE: return { ...state, balance: action.payload };
        case ACTION_SET_COMISSION: return { ...state, comission: action.payload };
        case ACTION_SET_FUNDS: return { ...state, funds: action.payload };
        case ACTION_SET_BALANCE_WALLET: return { ...state, balance_wallet: action.payload };
        case ACTION_SET_PROFIT_NOW: return { ...state, balance_profit_now: action.payload };
        case ACTION_SET_BALANCE_INVESTMENT: return { ...state, balance_investment: action.payload };
        case ACTION_SET_COMISSION_SUMM_NOT_PAYMENT: return { ...state, comission_summ_not_payment: action.payload };
        case ACTION_SET_STAT_USER: return { ...state, stat: action.payload };
        default: return state;
    }
}

export default fundReducer