import { ActionButton, Input, PasswordInput } from '../../ui-kit/components';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { google_auth, google_code } from '../../http/userAPI';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from "history";

import styles from './GoogleAuth.module.scss';
import { alertDanger, alertSuccess, MAIN_ROUTER } from '../../utils/consts';
import { actionSetGoogleAuth, actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { useEffect } from 'react';
import { Logo } from '../../ui-kit/assets';

export const GoogleAuth = () => {

  const [qr, setQr] = useState('')
  const [code, setCode] = useState('')

  const _async_load = async () => {
    let data = await google_auth()
    if (data.qr != '') setQr(data.qr)
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const browser_history = useSelector((state) => state.site.browser_history)

  const check_code = async (val) => {
    if (val.length == 6) {
      try {

        let data = await google_code(val)
        console.log(data)
        dispatch(actionSetGoogleAuth(1))
        dispatch(actionSetUserData(data))
        dispatch(actionSetIsAuth(true))
        
        navigate(browser_history[0])

        alertSuccess(dispatch, "Google Authenticator код успешно установлен")

      } catch (e) {
        setCode('')
        alertDanger(dispatch,'Неверный код')

      }
    } else {
      alertDanger(dispatch,"Введите 6-ти значный код")
    }
  }

  const setInputValueCode = async (val) => {
    setCode(val)
    if (val.length == 6) {
      try {
        await check_code(val)
      } catch (e) {
        alertDanger(dispatch,e.response.data.message)
      }
    }
  }

  useEffect(() => {
    _async_load()
  }, [])

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>
          <h1 className={styles.title}>Двухфакторная аутентификация</h1>
          <div className={styles.form}>
            <div>1. Сканируйте QR-код и получить токен из приложения.</div>
            <div style={{marginTop: 10}}>
              {qr !== '' && (
                  <img src={qr} alt=""/>
              )}
            </div>
            <br/>
            <div>2. Введите 2-FA токен, сгенерированный в приложении.</div>
            <div style={{marginTop: 10}}>
              <Input setInputValue={setInputValueCode} label="6-ти значный код"/>
            </div>
            <div style={{marginTop: 10, marginBottom: 50}}>
              <ActionButton>Включить</ActionButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
