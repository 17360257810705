import { useEffect, useState, Fragment } from 'react';
import { getNews } from '../../http/siteApi';
import styles from './News.module.scss';
import Parser from 'html-react-parser';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { NEWS_ROUTER } from '../../utils/consts';

export const News = (props) => {

  let page = props.page ?? ''

  const [news, setNews] = useState([])

  const load_data = async () => {
    let data = await getNews(4)
    setNews(data)
  }

  useEffect(() => {
    load_data()
  }, [])
  
  return (
    <div style={{marginTop: 40}}>
      <div>
        <h1 className={ page === 'main' ? styles['h1-main'] : styles.h1 }>Новости</h1>
      </div>
      <div className={ page === 'main' ? styles['news-main'] : styles.news }>
        <div className={ page === 'main' ? styles['container-main'] : styles.container }>
          { news.length > 0 && news.map((item) => {
            return (
              <div key={item._id}>
                <div className={styles.title}><Link to={NEWS_ROUTER}>{ item.title }</Link></div>
                <div className={styles.preview}>{ Parser(item.description.substr(0,300)) }...</div>
                <div className={styles.created_on}>{ moment(new Date(item.created_on)).format('DD/MM/YYYY') }</div>
              </div>
            )
          }) }
        </div>
      </div>
    </div>
  )
};
