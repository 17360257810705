import { 
    ACTION_SET_IS_AUTH,
    ACTION_SET_USER_DATA,
    ACTION_SET_GOOGLE_AUTH,
    ACTION_SET_STOP_CHECK_2FA,
    ACTION_SET_USER_ROOL,
} from '../actions/UserAction'

export const actionSetIsAuth = (payload) => {
    return {
        type: ACTION_SET_IS_AUTH,
        payload: payload
    }
}

export const actionSetUserData = (payload) => {
    return {
        type: ACTION_SET_USER_DATA,
        payload: payload
    }
}


export const actionSetGoogleAuth = (payload) => {
    return {
        type: ACTION_SET_GOOGLE_AUTH,
        payload: payload
    }
}

export const actionSetStopCheck2fa = (payload) => {
    return {
        type: ACTION_SET_STOP_CHECK_2FA,
        payload: payload
    }
}

export const actionSetUserRool = (payload) => {
    return {
        type: ACTION_SET_USER_ROOL,
        payload: payload
    }
}