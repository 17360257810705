import { ActionButton } from '../../ui-kit/components';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { google_auth, google_code } from '../../http/userAPI';

import styles from './GoogleAuthInfo.module.scss';
import { MAIN_ROUTER, GOOGLE_AUTH_ROUTER, alertDanger } from '../../utils/consts';
import { useEffect } from 'react';
import { Logo } from '../../ui-kit/assets';
import { RedCircle } from '../../ui-kit/assets';

export const GoogleAuthInfo = () => {

  const [qr, setQr] = useState('')
  const [code, setCode] = useState('')

  const dispatch = useDispatch()

  const _async_load = async () => {
    let data = await google_auth()
    if (data.qr != '') setQr(data.qr)
  }

  const navigate = useNavigate()

  const check_code = async (val) => {
    if (val.length == 6) {
      try {
        await google_code(val)
        navigate(MAIN_ROUTER)
      } catch (e) {
        setCode('')
        alertDanger(dispatch,'Неверный код')
      }
    }
  }

  const setInputValueCode = async (val) => {
    setCode(val)
    if (val.length == 6) {
      await check_code(val)
    }
  }

  useEffect(() => {
    _async_load()
  }, [])

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          <div style={{textAlign: 'center', marginBottom: 0}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>
          <h1 className={styles.title}>Двухфакторная аутентификация</h1>
          <div className={styles.form}>
            <div className={styles['circle-content']}><img src={RedCircle} className={styles.circle}/></div>
            <div className={styles['transparent-block']}>В целях безопасности без подключенной двухфакторной аутентификации ввод и вывод средств в вашем аккаунте заблокирован</div>
            <div className={styles.text}>Использование двухфакторной аутентификации является обязательным условием.</div>
            <div className={styles.text}>Воспользуйтесь Google Authenticator в приложении для Android или iPhone, чтобы ваш аккаунт всегда был под надежной защитой.</div>
            <div className={styles.buttons}>
              <div><Link to={GOOGLE_AUTH_ROUTER}><ActionButton>Настроить</ActionButton></Link></div>
              <div><Link to={MAIN_ROUTER} className={styles.continue}>Пропустить</Link></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
