export const pagesToShowCloseButton = {
    '/sign-in': true,
    '/sign-up': true,
    '/password-restore': true,
    '/restore-email-sent': true,
    '/google-auth': true,
    '/google-auth-info': true,
    '/deposit': true,
    '/deposit-final': true,
    '/choose-fund-step-1': true,
    '/choose-fund-step-2': true,
    '/choose-fund-step-3': true,
    '/withdrawal-step-1': true,
    '/withdrawal-step-2': true,
    '/withdrawal-step-3': true,
    '/withdrawal-fund-step-1': true,
    '/withdrawal-fund-step-2': true,
    '/withdrawal-fund-step-3': true,
    '/notifications': true,
    '/restore-password-create': true,
    '/google-2fa-reset': true,
};

export const getPathnameFromLocation = (location) => {
    let pathname = location.pathname
    let split = pathname.split('/')
    pathname = split.length == 3 ? "/" + split[1] : pathname
    return pathname
}