import { useState, useEffect } from 'react';
import { prettyUsd } from '../../../utils/consts';
import { Capital, CapitalMyself, Duration, Start, TradingResult } from '../../../ui-kit/assets';
import { Link, NavLink, useParams } from 'react-router-dom';
import styles from './HeaderFund.module.scss';
import classNames from 'classnames';

export const HeaderFund = (params) => {

  const [ fund, setFund ] = useState({})

  const { cpu } = useParams()

  useEffect(() => {
    setFund(params.fund)
  }, [params])


  const navLinks = [
    {
      text: "Доходность",
      to: '/fund/' + cpu,
    },
    {
      text: "Инвестиции",
      to: '/fund-investments/' + cpu,
    },
    {
      text: "Торговля",
      to: '/fund-trading/' + cpu,
    },
  ]

  return (
    <>
      <div className={styles['container-name']}>
        <div className={styles['container-left']}>
          <div className={styles['container-left-uptitle']}>Фонд</div>
          <div className={styles['container-left-title']}>{ fund?.name }</div>
        </div>
        <div className={styles['container-right']}>
          <div className={styles['yield-data']}>
            <div className={styles['right-yield']}>Доходность</div>
            <div className={styles['right-profit']}>{ fund?.profit_pr }%</div>
          </div>
          <div>
            <Link to={'/choose-fund-step-1/' + cpu}>
              <div className={styles.button}>Инвестировать</div>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles['flex-icon-container']}>

        <div className={styles['flex-icon-item']}>
         <div className={styles['flex-icon-img']}>
            <img src={Start} className={styles.icon}/>
          </div>
          <div className={styles.left}>
            <div className={styles.top}>В рейтинге</div>
            <div className={styles.bottom}>{ fund?.rating }</div>
          </div>
        </div>

        <div className={styles['flex-icon-item']}>
          <div className={styles['flex-icon-img']}>
            <img src={TradingResult} className={styles.icon}/>
          </div>
          <div className={styles.left}>
            <div className={styles.top}>Торговый результат</div>
            <div className={styles.bottom}>{ prettyUsd(fund?.profit_amount) } USD</div>
          </div>
        </div>

        <div className={styles['flex-icon-item']}>
          <div className={styles['flex-icon-img']}>
            <img src={Capital} className={styles.icon}/>
          </div>
          <div className={styles.left}>
            <div className={styles.top}>Средства в управлении</div>
            <div className={styles.bottom}>{ prettyUsd(fund?.capital_total) } USD</div>
          </div>
        </div>

        <div className={styles['flex-icon-item']}>
          <div className={styles['flex-icon-img']}>
            <img src={CapitalMyself} className={styles.icon}/>
          </div>
          <div className={styles.left}>
            <div className={styles.top}>Капитал управляющего</div>
            <div className={styles.bottom}>{ prettyUsd(fund?.capital_myself) } USD</div>
          </div>
        </div>

        <div className={styles['flex-icon-item']}>
          <div className={styles['flex-icon-img']}>
            <img src={Duration} className={styles.icon}/>
          </div>
          <div className={styles.left}>
            <div className={styles.top}>Возраст счета</div>
            <div className={styles.bottom}>{ fund?.duration }</div>
          </div>
        </div>

      </div>


      <nav className={styles['body-links']}>
        {navLinks.map(({ to, text }) => (
          <NavLink className={styles.item} to={to}>
            {({ isActive }) => (
              <>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span
                    className={classNames(styles.item__text, {
                      [styles['item--active']]: isActive,
                    })}
                  >
                    {text}
                  </span>
                </div>
              </>
            )}
          </NavLink>
        ))}
      </nav>

      
    </>
  );
};
