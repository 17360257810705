import { combineReducers } from 'redux'
import userReducer from './userReducer'
import paymentReducer from './paymentReducer'
import fundReducer from './fundReducer'
import siteReducer from './siteReducer'

export default combineReducers({
    user: userReducer,
    payment: paymentReducer,
    fund: fundReducer,
    site: siteReducer,
})