import { useEffect, useState } from 'react'
import { getNews, getNotifications, setNotificationReaded } from '../../http/siteApi';
import moment from 'moment'
import Parser from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux'
import { ActionButton } from '../../ui-kit/components';

import styles from './Notifications.module.scss';
import { nl2br } from '../../utils/consts';
import { useNavigate } from 'react-router-dom';
import { actionSetAmountNotification, actionSetNotifications, actionSetNotificationStatus } from '../../store/actionCreators/siteActionCreator';

export const Notifications = () => {

  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(true)
  const [notifications, setNotifications] = useState([])

  const dispatch = useDispatch()

  const browser_history = useSelector((state) => state.site.browser_history)
  const [ index, setIndex ] = useState(0)

  const setReaded = async(index_readed) => {
    if (notifications[index_readed]) {
      await setNotificationReaded(notifications[index_readed].id)
    }
  }

  const setReadedById = async(id) => {
    await setNotificationReaded(id)
  }

  useEffect(() => {

  }, notifications)

  const load_data = async() => {

    let data = await getNotifications()
    setNotifications(data)
    dispatch(actionSetNotifications(data))
    dispatch(actionSetAmountNotification(data.length))
 
    dispatch(actionSetNotificationStatus(0))

    setLoading(false)
    
    if (data.length > 0) {
      await setReadedById(data[0].id)
    }
    
  }

  useEffect(() => {
    load_data()
  },[])

  const next = async () => {
    if (index === (notifications.length - 1)) {
      // Закрываем модальное окно
      dispatch(actionSetNotifications([]))
      navigate(browser_history[0])
    }
    setIndex(index + 1)
    await setReaded(index + 1)
  }

  const back = async () => {
    if (index === 0) return
    setIndex(index - 1)
    await setReaded(index - 1)
  }

  const skip = async () => {
    await setNotificationReaded(0)
    dispatch(actionSetNotifications([]))
    navigate(browser_history[0])
  }

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>

          { loading === false && (
            <>
          
            { notifications.length > 0 && (
              <>
                <h1 className={styles.title}>
                  { notifications[index].table === 'news' && <>Новости</>}
                </h1>

                <div className={styles.content_notification}>
                  <div className={styles.content_title}>{ notifications[index].table === 'news' && notifications[index].data?.title}</div>
                  <div className={styles.content_body}>{ notifications[index].table === 'news' && Parser(nl2br(notifications[index].data?.description))}</div>
                  <div className={styles.content_date}>{ notifications[index].table === 'news' && moment(new Date(notifications[index].data?.created_on)).format('DD/MM/YYYY') }</div>
                </div>

                <div className={styles.form}>
                  <div className={styles.form_left}><ActionButton onClick={() => skip()} className={styles.button_blank}>Пропустить все ({ notifications.length })</ActionButton></div>
                  <div className={styles.form_right}>
                    { index > 0 && <ActionButton onClick={() => back()} className={styles.button_blank_back}>Назад</ActionButton> }
                    <ActionButton onClick={() => next()} className={styles.button}>Далее</ActionButton>
                  </div>
                </div>
              </>
            ) }

              { notifications.length == 0 && (
              <>
                <h1 className={styles.title}>Уведомления</h1>
                <div className={styles.content_notification} style={{textAlign: "center"}}>
                  Нет новых уведомлений
                </div>
              </>
            ) }

            </>
          )}
        </div>
      </div>
    </> 
  );
};
