import {
    ACTION_SET_IS_AUTH,
    ACTION_SET_USER_DATA,
    ACTION_SET_GOOGLE_AUTH,
    ACTION_SET_STOP_CHECK_2FA,
    ACTION_SET_USER_ROOL,
} from '../actions/UserAction'

const initialState = {
    user: {},
    isAuth: false,
    google_auth: false,
    stop_check_2fa: 0,
    rool: 'user',
};


const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_SET_IS_AUTH: return { ...state, isAuth: action.payload };
        case ACTION_SET_USER_DATA: return { ...state, user: action.payload };
        case ACTION_SET_GOOGLE_AUTH: return { ...state, google_auth: action.payload };
        case ACTION_SET_STOP_CHECK_2FA: return { ...state, stop_check_2fa: action.payload };
        case ACTION_SET_USER_ROOL: return { ...state, rool: action.payload };
        default: return state;
    }
}

export default userReducer