import {
  HeroSection,
} from '../../components';
import { News } from '../../components/News/News';

export const MainPage = () => {
  return (
    <>
      <HeroSection />
      <News page="main"/>
    </>
  );
};
