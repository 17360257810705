import { useWindowSize } from '../../hooks';

import styles from './Dashboard.module.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { News } from '../../components/News/News';
import { BalanceInfo } from '../../components/Dashboard/BalanceInfo/BalanceInfo';
import { GraphProfit } from '../../components/Dashboard/GraphProfit';
import { InvestmentActive } from '../../components/Dashboard/InvestmentActive/InvestmentActive';

export const Dashboard = () => {

  const { isMobile } = useWindowSize();
  const dispatch = useDispatch()

  const load_data = async () => {

  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <div className={styles.body}>

      <div className={styles.container}>
        <div className={styles.item}><BalanceInfo/></div>
        <div className={styles.item}><InvestmentActive/></div>
      </div>

      <div style={{clear: 'both'}}></div>
      
      <News/>
    </div>
  );
};
