import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetGraph } from '../../../store/actionCreators/paymentActionCreator';
import { ControlButtonMenu, Card } from '../../../ui-kit/components';
import styles from './Graphs.module.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';


export const Graphs = () => {

  const [period_selected, setPeriodSelected] = useState('chart_full')
  const graph = useSelector((state) => state.payment.graph)

  const options = {
    responsive: true,
    height: 360,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  }


  let data = [
    {
      name: 'Все время',
      value: 'chart_full',
    },
    {
      name: 'Месяц',
      value: 'chart_month',
    },
    {
      name: 'Неделя',
      value: 'chart_week',
    },
    {
      name: '24 ч',
      value: 'chart_day',
    }
  ]



  const [data_graph, setDataGraph] = useState({})

  
  useEffect(() => {

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    )

    let labels = []
    let data_for_graph = []
    if (graph[period_selected]) {
      graph[period_selected].map((i) => {
        labels.push(moment(i.date).format('DD/MM/YYYY в HH:mm'))
        data_for_graph.push(i.profit)
      })
    }

    setDataGraph({
      labels,
      datasets: [
        {
          label: 'Доход, $',
          data: data_for_graph,
          borderColor: 'rgb(50, 200, 50)',
          backgroundColor: 'rgba(50, 200, 50)',
        }
      ],
    })

  },[graph, period_selected])

  return (
    <div className={styles.wrapper}>
      <ControlButtonMenu setActiveButton={setPeriodSelected} className={styles.controls} buttons={data} />
      <Card className={styles.graph}>
        <div className={styles.graphpadding}>
          { data_graph.labels && data_graph.datasets.length > 0 &&  <Line height={185} options={options} data={data_graph} /> }
        </div>
      </Card>
    </div>
  );
};
