import { Link, useLocation } from 'react-router-dom';
import { MAIN_ROUTER, SUPPORT_ROUTER } from '../../utils/consts';
import styles from './Footer.module.scss';

const pagesToShowCloseButton = {
  '/sign-in': true,
  '/sign-up': true,
  '/password-restore': true,
  '/restore-email-sent': true,
  '/google-auth': true,
  '/google-auth-info': true,
  '/deposit': true,
  '/deposit-final': true,
  '/choose-fund': true,
  '/withdrawal-step-1': true,
  '/withdrawal-step-2': true,
  '/withdrawal-step-3': true,
};

export const Footer = () => {
  
  const location = useLocation();
  let pathname = location.pathname
  let split = pathname.split('/')
  pathname = split.length == 3 ? "/" + split[1] : pathname
  const isOnLoginPage = pagesToShowCloseButton[pathname];

  return (
    (!isOnLoginPage && (
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.item}>Copyright © 2022 x3crypto</div>
          {false && (
            <>
              <div className={styles.item}><Link to={MAIN_ROUTER}>FAQ</Link></div>
              <div className={styles.item}><Link to={SUPPORT_ROUTER}>Помощь</Link></div>
              <div className={styles.item}><Link to={MAIN_ROUTER}>Публичная оферта</Link></div>
              <div className={styles.item}><Link to={MAIN_ROUTER}>White paper</Link></div>
            </>
          )}
        </div>  
      </footer>
    ))
  )
};
