import styles from './FundProfitabilityPeriod.module.scss';

import { useEffect, useState } from 'react';
import { IssetDeclaration, NotIssetDeclaration } from '../../../ui-kit/assets';
import { Skeleton } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'


export const FundProfitabilityPeriod = (params) => {

  const [step, setStep] = useState(1)
  const [ options, setOptions ] = useState(null)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (step == 1) optionCreator(params.fund.progressive_result)
    if (step == 2) optionCreator(params.fund.month_profit)
  }, [step])

  
  const optionCreator = (data) => {

    let options_temp = {
      chart: {
          type: 'column',
          backgroundColor: 'transparent'
      },
      title: null,
      subtitle: null,
      accessibility: {
          announceNewData: {
              enabled: true
          }
      },
      xAxis: {
          type: 'category'
      },
      yAxis: {
          title: null  
      },
      legend: {
          enabled: false
      },
      plotOptions: {
          series: {
              borderWidth: 0,
              dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f}%',
                  style: {
                    color: "white"
                  }
              }
          }
      },
  
      tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat: 'Доходность <b>{point.y:.2f}%</b><br/>'
      },
  
      series: [
        {
          name: null,
          colorByPoint: true,
          data: data
        }
      ],
    }

    setOptions(options_temp)

  }


  const _load_async = async (fund) => {

    if (fund._id) {

      // let data = await getStatProfitPeriod(fund?._id)
      // setArrayPeriodData(data)
      
      optionCreator(fund.progressive_result)

      setLoading(false)

    }
  }

  useEffect(() => {
    if (params.fund) {
      _load_async(params.fund)
    }
  },[params])


  return (
    <div className={styles.body}>

      <div className={styles.title_declaration}>
        <div className={styles.title_declaration_title}>Доходность фонда за период</div>
      </div>
      
      <div className={styles['chart-block']}>
        
          <div className={styles['menu']}>
            <div onClick={() => setStep(1)} className={styles[step == 1 ? 'active' : 'not_active']}>Прогрессивно</div>
            <div onClick={() => setStep(2)} className={styles[step == 1 ? 'not_active' : 'active']}>По месяцам</div>
          </div>

          <div className={styles['char-data']}> 

            { loading == false && options !== null && (
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            ) }

            { loading && <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="100%" height={300} /> }

          </div>
        
      </div>
    </div>
  );
};
