import { useEffect, useState } from 'react';
import { getComission, getSettings, getWithdrawals, updateSetting } from '../../../http/adminApi';
import { Select } from '../../../ui-kit/components';
import styles from './AdminWithdrawal.module.scss';

export const AdminWithdrawal = () => {

  let [withdrawals, setWithdrawals] = useState([])


  const _asyncLoad = async () => {
    let data = await getWithdrawals()
    setWithdrawals(data)
  }

  
  useEffect(() => {
    _asyncLoad()
  }, [])
  

  

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Вывод средств</h1>

      <table>
        { withdrawals.length > 0 && withdrawals.map((item) => {
          <tr>
            <td>{item._id}</td>
          </tr>
        })}
      </table>

    </div>
  );
};
