import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './ActionButton.module.scss';

export const ActionButton = ({ children, size, className, onClick, type, to, muted, disabled }) => {
  const classes = {
    [styles['action-button--sm']]: size === 'sm',
    [styles['action-button--muted']]: muted,
    [styles['action-button--disabled']]: disabled, // Добавьте класс для стилизации disabled
  };

  if (type === 'link')
    return (
      <Link
        to={to}
        className={classNames(styles['action-button'], classes, className, { [styles.disabled]: disabled })}
        onClick={disabled ? undefined : onClick} // Блокируем onClick, если disabled=true
      >
        {children}
      </Link>
    );

  return (
    <button 
      className={classNames(styles['action-button'], classes, className)} 
      onClick={disabled ? undefined : onClick} // Блокируем onClick
      disabled={disabled} // Устанавливаем свойство disabled для кнопки
    >
      {children}
    </button>
  );
};
