import styles from './StatFundProfit.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getStatProfit } from '../../../http/fundApi';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

export const StatFundProfit = (params) => {

  const [ options, setOptions ] = useState(null)
  const [loading, setLoading] = useState(true)

  const load_data = async (fund) => {
    
    if (fund._id) {

      let data = await getStatProfit(fund?._id)

      setLoading(false)

      let options_temp = {
          chart: {
              zoomType: 'x',
              backgroundColor: 'transparent'
          },
          title: null,
          subtitle: null,
          xAxis: {
              type: 'datetime'
          },
          yAxis: {
              title: {
                  text: 'USD'
              }
          },
          legend: {
              enabled: false
          },
          plotOptions: {
              area: {
                  fillColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                      stops: [
                          [0, Highcharts.getOptions().colors[0]],
                          [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                      ]
                  },
                  marker: {
                      radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              }
          },

          series: [{
              type: 'area',
              name: 'USD',
              data: data
          }]
      }


      setOptions(options_temp)

    }

  }

  

  useEffect(() => {
    if (params.fund) {
      load_data(params.fund)
    }
  }, [params])


  return (
    <div className={styles.body}>
      <div className={styles.title}>Доходность фонда</div>
      <div className={styles['chart-block']}>

        { loading && (<Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="100%" height={300} />) }

        { loading === false && options !== null && 
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          /> }
      </div>
    </div>
  );
};
