import { ActionButton, Input, PasswordInput } from '../../ui-kit/components';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { register, finishRegister } from '../../http/userAPI';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SignUp.module.scss';
import { alertDanger, MAIN_ROUTER } from '../../utils/consts';
import { actionSetIsAuth, actionSetUserData } from '../../store/actionCreators/userActionCreator';
import { Logo } from '../../ui-kit/assets';


export const SignUp = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)
  const [code, setCode] = useState('')

  const isAuth = useSelector(store => store.user.isAuth)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const finishSignUp = async () => {

    if (code.length != 4) {
      alertDanger(dispatch, "Введите 4-х значный код подтверждения")
      return
    }

    if (isAuth) {
      navigate(MAIN_ROUTER)
    }

    try {

      let data = await finishRegister(email, password, confirm_password, code)

      dispatch(actionSetIsAuth(true))
      dispatch(actionSetUserData(data)) 

      navigate(MAIN_ROUTER)

    } catch(e) {
        
      alertDanger(dispatch, e.response.data.message)

    }

  }

  const clickSignUp = async () => {

    if (isAuth) {
      navigate(MAIN_ROUTER)
    }
    
    try {

      let data = await register(email, password, confirm_password)

      setStep(2)

    } catch(e) {
        
      alertDanger(dispatch, e.response.data.message)

    }

  }


  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles.content}>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>
          <h1 className={styles.title}>Регистрация</h1>
          <div className={styles.form}>
            { step == 1 && (
              <>
                <Input control={{disabled: step == 1 ? false : true}} autocomplete="off" className={styles.input} setInputValue={(val) => setEmail(val)} label='E-mail' dynamicLabel />
                <PasswordInput control={{disabled: step == 1 ? false : true}} autocomplete="off" className={styles.input} setInputValue={(val) => setPassword(val)} label="Пароль" />
                <PasswordInput control={{disabled: step == 1 ? false : true}} autocomplete="off" className={styles.input} setInputValue={(val) => setConfirmPassword(val)} label="Повторите пароль" dynamicLabel />
                <Link className={styles.link} to='/sign-in'>
                  Есть аккаунт?
                </Link>
              </>
            ) }

            { step == 2 && (
              <>
                <div style={{marginTop: 10, maxWidth: 300, textAlign: 'center'}}>Введите код подтверждения, отправленный на Email</div>
                <div style={{marginBottom: 10, marginTop: 10}}>
                  <div><Input autocomplete="off" className={styles.input} setInputValue={(val) => setCode(val)} label="Код подтверждения" dynamicLabel /></div>
                </div>
              </>
            ) }

            { false && <ActionButton className={styles.button} onClick={() => step == 1 ? clickSignUp() : finishSignUp()}>{ step == 1 ? 'Создать аккаунт' : 'Завершить регистрацию' }</ActionButton> }
        
          </div>
        </div>
      </div>
    </>
  );
};
