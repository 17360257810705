import styles from './FundPropertyResult.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getStatFundDistribution } from '../../../http/fundApi';
import { useEffect, useState } from 'react';
import { FundPropCalendarIcon, FundPropCapitalIcon, FundPropInvestingIcon, FundPropPersonIcon, FundPropPersonsIcon, FundPropProfitIcon } from '../../../ui-kit/assets';
import moment from 'moment'
import { prettyUsd } from '../../../utils/consts';

export const FundPropertyResult = (params) => {

  const [name, setName] = useState("")
  const [capital_myself, setCapitalMyself] = useState("")
  const [amount_investor, setAmountInvestor] = useState("")
  const [date, setDate] = useState("")
  const [capital, setCapital] = useState("")
  const [profit, setProfit] = useState("")

  const [maximum_relative_profit, setMaximumRelativeProfit] = useState(0)
  const [maximum_relative_loss, setMaximumRelativeLoss] = useState(0)
  const [maximum_daily_profit, setMaximumDailyProfit] = useState(0)
  const [maximum_daily_loss, setMaximumDailyLoss] = useState(0)
  const [average_daily_profit, setAverageDailyProfit] = useState(0)
  const [average_daily_loss, setAverageDailyLoss] = useState(0)
  const [geometric_mean_return, setGeometricMeanReturn] = useState(0)
  const [daily_return_volatility, setDailyReturnVolatility] = useState(0)
  const [current_drawdown, setCurrentDrawdown] = useState(0)


  useEffect(() => {

    if (params.fund) {
        
      setName(params.fund.name)
      setCapitalMyself(params.fund.capital_myself)
      setAmountInvestor(params.fund.amount_investor)
      setCapital(params.fund.capital_total)
      setProfit(params.fund.profit_amount)
      setDate(moment(params.fund.date_created).format("MM.DD.YYYY"))

      setMaximumRelativeProfit(params.fund.maximum_relative_profit)
      setMaximumRelativeLoss(params.fund.maximum_relative_loss)
      setMaximumDailyProfit(params.fund.maximum_daily_profit)
      setMaximumDailyLoss(params.fund.maximum_daily_loss)
      setAverageDailyProfit(params.fund.average_daily_profit)
      setAverageDailyLoss(params.fund.average_daily_loss)
      setGeometricMeanReturn(params.fund.geometric_mean_return)
      setDailyReturnVolatility(params.fund.daily_return_volatility)
      setCurrentDrawdown(params.fund.current_drawdown)

    }

  },[params])

  return (
    <div className={styles.body}>
      <div className={styles.title}>Показатели торговли</div>
      <div className={styles['chart-block']}>
        
        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальная относительная прибыль</div>
            <div className={styles['fund-prop-item-right']}>{ maximum_relative_profit }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальная дневная прибыль</div>
            <div className={styles['fund-prop-item-right']}>{ maximum_daily_profit } USD</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Средняя дневная прибыль</div>
            <div className={styles['fund-prop-item-right']}>{ average_daily_profit } USD</div>
          </div>

        </div>

        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальный относительный убыток</div>
            <div className={styles['fund-prop-item-right']}>{ maximum_relative_loss }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальный дневной убыток</div>
            <div className={styles['fund-prop-item-right']}>{ maximum_daily_loss } USD</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Средний дневной убыток</div>
            <div className={styles['fund-prop-item-right']}>{ average_daily_loss } USD</div>
          </div>

        </div>

        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Средняя геометрическая доходность</div>
            <div className={styles['fund-prop-item-right']}>{ geometric_mean_return }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Волатильность дневной доходности</div>
            <div className={styles['fund-prop-item-right']}>{ daily_return_volatility } USD</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Текущая просадка</div>
            <div className={styles['fund-prop-item-right']}>{ current_drawdown }%</div>
          </div>

        </div>
      </div>
    </div>
  );
};
