import { deleteNewsAdmin, getNewsListAdmin } from '../../../http/adminApi';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import styles from './AdminNews.module.scss';

export const AdminNews = () => {

  const [news, setNews] = useState([])

  const load_data = async() => {
    let data = await getNewsListAdmin()
    console.log("getNewsListAdmin")
    console.log(data)
    setNews(data)
  }

  const deleteNews = async(news_id) => {
    await deleteNewsAdmin(news_id)
    await load_data()
  }

  useEffect(() => {
    load_data()
  }, [])

  return (
    <div className={styles.body}>
      <div className={styles.flex}>
        <div><h1 style={{fontSize: 20}}>Новости</h1></div>
        <div><Link to="/admin/news/create">+ Добавить новость</Link></div>
      </div>
      <table className={styles.table}>
        { news.map((item) => {
          return (
            <tr>
              <td>{ moment(item.created_on).format("YYYY/MM/DD HH:mm") }</td>
              <td>{ item.title }</td>
              <td style={{width: 150, textAlign: 'right'}}>
                <Link to={"/admin/news/update/" + item._id}>[ редактировать ]</Link>
              </td>
              <td style={{width: 100, textAlign: 'right'}}>
                <span style={{cursor: "pointer"}} onClick={() => deleteNews(item._id)}>[ удалить ]</span>
              </td>
            </tr>
          )
        })}
      </table>
    </div>
  );
};
