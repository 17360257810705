import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { prettyUsd } from '../../../utils/consts';
import styles from './StatProfit.module.scss';

export const StatProfit = () => {

  const stat = useSelector((state) => state.fund.stat)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (
      stat?.profit_year !== null &&
      stat?.profit_month !== null &&
      stat?.profit_week !== null &&
      stat?.profit_24 !== null
    ) {
      setLoading(false)
    }
  },[stat])

  return (
    <div className={styles.body}>
      <div className={styles.title}>Прибыль</div>

      { loading && (
        <div className={styles.container}>

          <div>
            <div className={styles['title-item']}>Прибыль год</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль месяц</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль неделя</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль 24 ч</div>
            <div className={styles['amount-item']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></div>
          </div>

        </div>
      )}

      
      { !loading && (
        <div className={styles.container}>

          <div>
            <div className={styles['title-item']}>Прибыль год</div>
            <div className={styles['amount-item']}>{ prettyUsd(stat.profit_year) } USD</div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль месяц</div>
            <div className={styles['amount-item']}>{ prettyUsd(stat.profit_month) } USD</div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль неделя</div>
            <div className={styles['amount-item']}>{ prettyUsd(stat.profit_week) } USD</div>
          </div>

          <div>
            <div className={styles['title-item']}>Прибыль 24 ч</div>
            <div className={styles['amount-item']}>{ prettyUsd(stat.profit_24) } USD</div>
          </div>

        </div>
      )}
      
    </div>
  );
};
