import { useEffect, useMemo, useState } from 'react';
import { Arrow, ColoredText, Table, Card, HollowButton } from '../../ui-kit/components';
import { getHistory } from '../../http/paymentApi';
import { crypto_icons } from '../../utils/symbol';
import styles from './History.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export const History = () => {

  const [history, setHistory] = useState([])

  const load_data = async () => {
    let data = await getHistory()
    setHistory(data)
  }

  useEffect(() => {
    load_data()
  },[])

  const columns = useMemo(
    () => [
      {
        Header: 'Дата',
        accessor: 'date',
        Cell: ({ value }) => <p className={styles.date}>{value}</p>,
      },
      {
        Header: 'Операция',
        accessor: 'operation',
        Cell: ({ value }) => (
          <div>
            <div className={styles.operation__info}>
              <img className={styles.operation__icon} src={crypto_icons[value.icon]} alt='' />
              <ColoredText color={value.profit > 0 ? 'green' : 'red'}>{value.value} </ColoredText>
              <Arrow
                className={styles.operation__arrow}
                color={value.profit >= 0 ? 'green' : 'red'}
                active={value.profit >= 0 ? 'green' : 'red'}
              />
            </div>
            <p className={styles.operation__text}>{value.status_text}</p>
          </div>
        ),
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => (
          <ColoredText color={value.status ? 'green' : 'red'}>{value.status ? 'Завершён' : 'В ожидании'}</ColoredText>
        ),
        maxWidth: 100,
      },
      {
        Header: '',
        accessor: 'link',
        Cell: ({ value }) => {
          if (value !== '') {
            return <HollowButton type="link" to={value} className={styles['accordeon-item__button']}>Детали</HollowButton>
          }
        },
        maxWidth: 80,
      },
    ],
    []
  );


  return (
    <div className={styles.body}>
      <h1 className={styles.title}>История</h1>

      {/* Desktop only element */}
      <Table
        className={classNames(styles.table, styles['desktop-element--block'])}
        columns={columns}
        data={history}
        rowClassName={styles.table__row}
      />
      {/* Desktop only element */}

      {/* Mobile only element */}
      <Card className={classNames(styles.history, styles['mobile-element--block'])}>
        {history.map(({ date, operation, status }) => (
          <div className={styles['history-item']}>
            <div className={styles['history-item__date']}>{date}</div>
            <div className={styles['history-item__operation-and-status']}>
              <div>
                <div className={styles.operation__info}>
                  <img className={styles.operation__icon} src={crypto_icons[operation.icon]} alt='' />
                  <ColoredText color={operation.profit ? 'green' : 'red'}>
                    {operation.value}{' '}
                  </ColoredText>
                  <Arrow
                    className={styles.operation__arrow}
                    color={operation.profit ? 'green' : 'red'}
                    active={operation.profit}
                  />
                </div>
                <p className={styles.operation__text}>{operation.status_text}</p>
                { operation.link !== '' && <div><Link className={styles.link_more} to={operation.link}>Детали операции</Link></div>}
              </div>
              <ColoredText
                className={styles['history-item__status']}
                color={status ? 'green' : 'red'}
              >
                {status ? 'Завершён' : 'В ожидании'}
              </ColoredText>
            </div>
          </div>
        ))}
      </Card>
      {/* Mobile only element */}
    </div>
  );
};
