import styles from './StatFundDistribution.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getStatFundDistribution } from '../../../http/fundApi';
import { useEffect, useState } from 'react';

export const StatFundDistribution = (params) => {

  const [ options, setOptions ] = useState(null)
  const [ array_table, setArrayTable ] = useState([])

  let pieColors = (function () {
    let colors = [],
          base = Highcharts.getOptions().colors[0],
          i;

      for (i = 0; i < 10; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
      }
      return colors;
  }());

  const load_data = async (fund) => {
    
    if (fund._id) {

      let data = await getStatFundDistribution(fund?._id)

      setArrayTable(data.array_table)

      let temp_option = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          backgroundColor: 'transparent',
          styles: {
            fontFamily: 'Arial',
            fontSize: '12px'
          },
        },
        title: null,
        tooltip: {
          pointFormat: '{series.name}: {point.percentage:.1f}%'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: pieColors,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
              distance: -50,
              filter: {
                property: 'percentage',
                operator: '>',
                value: 4
              }
            }
          }
        },
        series: [{
          name: 'Объем',
          data: data.array_graph
        }]
      }

      setOptions(temp_option)

    }
    
  }

  useEffect(() => {
    if (params.fund) {
      load_data(params.fund)
    }
  }, [params])



  return (
    <div className={styles.body}>
      <div className={styles.title}>Распределение средств</div>
      <div className={styles['title-underline']}>Диаграмма распределения средств отображает процентное соотношение доли инвестиционных счетов в составе рассматриваемого фонда</div>
      <div className={styles['chart-block']}>
        <div className={styles['chart-block-left']}>
          { options !== null && 
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          /> }
        </div>
        <div className={styles['chart-block-right']}>
          { array_table.length > 0 && 
            <table className={styles.table}>
              <tr className={styles['header-title']}>
                <td>Пользователь</td>
                <td>Доля, %</td>
                <td>Сумма, USD</td>
              </tr>
              { array_table.map((item) => {
                return (
                  <tr>
                    <td>{ item.name } <span className={styles['is-rool']}>{ item.is_rool ? 'управляющий' : '' }</span></td>
                    <td>{ item.pr }%</td>
                    <td>{ item.amount } USD</td>
                  </tr>
                )
              })}
            </table>
          }

        </div>
      </div>
    </div>
  );
};
