import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes, authRoutes, adminRoutes } from './router';
import { actionSetIsAuth, actionSetUserData, actionSetUserRool } from '../store/actionCreators/userActionCreator'
import { MAIN_ROUTER, SIGNIN_ROUTER } from '../utils/consts'
import { useWindowSize } from '../hooks';
import { Header, Footer, MobileNav } from '../components';
import '../index.scss';
import styles from './App.module.scss';
import { useEffect, useState } from 'react'
import { checkIsAuth } from '../http/userAPI'
import { useSelector, useDispatch } from 'react-redux'

export const App = () => {

  const dispatch = useDispatch()
  const { isMobile } = useWindowSize();
  const isAuth = useSelector(store => store.user.isAuth)
  const rool = useSelector(store => store.user.rool)

  const [loading, setLoading] = useState(true)

  checkIsAuth().then( data => {
    
    dispatch(actionSetIsAuth(true))
    dispatch(actionSetUserData(data)) 
    dispatch(actionSetUserRool(data.rool)) 

  }).finally(()=>{

    setLoading(false)

  }).catch((e) => {

    console.log(e.response.data)
    
  })

  if (loading) {
    return (
      <div>
        Идет загрузка ....
      </div>
    )
  }

  return (
    <div className={styles.body}>
      <div className={styles.app}>
        <BrowserRouter>

          <Header logged={isAuth} />

          <main className={styles.main}>

            <Routes>

              {publicRoutes.map(elem => (
                <Route {...elem} key={elem.name} />
              ))}

              {isAuth && authRoutes.map(elem => (
                <Route {...elem} key={elem.name} />
              ))}

              {isAuth && rool == 'admin' && adminRoutes.map(elem => (
                <Route {...elem} key={elem.name} />
              ))}

              <Route
                path="*"
                element={<Navigate to={SIGNIN_ROUTER} replace />}
              />

            </Routes>

          </main>

          <Footer />

          {false && isMobile && isAuth && <MobileNav />}

        </BrowserRouter>
      </div>
    </div>
  );
};
