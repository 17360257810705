import styles from './FundProfitability.module.scss';

import { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { IssetDeclaration, NotIssetDeclaration } from '../../../ui-kit/assets';
import { getStatProfitPeriod } from '../../../http/fundApi';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment'

export const FundProfitability = (params) => {

  const [step, setStep] = useState(4)
  const [date, setDate] = useState('Дата')

  const [ options, setOptions ] = useState(null)

  const [amount, setAmount] = useState(0)
  const [amount_result, setAmountResult] = useState(0)
  const [amount_profit, setAmountProfit] = useState(0)
  const [loading, setLoading] = useState(true)

  const [array_period_data, setArrayPeriodData] = useState([])

  const calculateAmountResult = (amount_data) => {

    if (array_period_data?.array_result_year && array_period_data.array_result_year.length > 0) {

      let arr = []
      
      let date_start = ""
      let date_end = Date.now()

      if (step == 1) {
        arr = array_period_data['array_result_day']
        date_start = Date.now() - 24 * 60 * 60 * 1000
      } else if (step == 2) {
        arr = array_period_data['array_result_week']
        date_start = Date.now() - 7 * 24 * 60 * 60 * 1000
      } else if (step == 3) {
        arr = array_period_data['array_result_month']
        date_start = Date.now() - 30 * 24 * 60 * 60 * 1000
      } else if (step == 4) {
        arr = array_period_data['array_result_year']
        date_start = Date.now() - 365 * 24 * 60 * 60 * 1000
      }
      
      date_start = moment(new Date(date_start)).format('YYYY-MM-DD')
      date_end = moment(new Date(date_end)).format('YYYY-MM-DD')

      if (date_start == date_end) {
        setDate("24 ч")
      } else {
        setDate(date_start + " - " + date_end)
      }

      let pr = 0
      if (arr.length > 0) {
        pr = arr[arr.length - 1][1]
        pr = pr / 100
      }

      amount_data = Number(amount_data)

      let amount_result = Math.round(amount_data * pr)

      setAmountResult(amount_result)
      setAmountProfit(Math.round(amount_data + amount_result))

    }

  }

  const setAmountValue = (e) => {

    setAmount(e.target.value)

    calculateAmountResult(e.target.value)
    
  }

  const optionCreator = (data) => {

    let options_temp = {
      chart: {
        zoomType: 'x',
        backgroundColor: 'transparent'
      },
      title: null,
      subtitle: null,
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: '%'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },

      series: [{
        type: 'area',
        name: '%',
        data: data
      }]
      
    }

    setOptions(options_temp)

  }

  const _load_async = async (fund) => {

    if (fund._id) {

      let data = {}
      data['array_result_day'] = fund?.array_result_day ?? []
      data['array_result_week'] = fund?.array_result_week ?? []
      data['array_result_month'] = fund?.array_result_month ?? []
      data['array_result_year'] = fund?.array_result_year ?? []
      setArrayPeriodData(data)
      
      optionCreator(data['array_result_year'])

      let date_end = Date.now()
      let date_start = Date.now() - 365 * 24 * 60 * 60 * 1000
        
      date_start = moment(new Date(date_start)).format('DD/MM/YYYY')
      date_end = moment(new Date(date_end)).format('DD/MM/YYYY')

      setDate(date_start + " - " + date_end)

      setLoading(false)

    }
  }

  useEffect(() => {
    if (params.fund) {
      _load_async(params.fund)
    }
  },[params])


  useEffect(() => { 
    if (step == 1) {
      optionCreator(array_period_data['array_result_day'])
    } else if (step == 2) {
      optionCreator(array_period_data['array_result_week'])
    } else if (step == 3) {
      optionCreator(array_period_data['array_result_month'])
    } else if (step == 4) {
      optionCreator(array_period_data['array_result_year'])
    }

    calculateAmountResult(amount)

  },[step])
  
  return (
    <div className={styles.body}>

      <div className={styles.title_declaration}>
        <div className={styles.title_declaration_title}>Доходность фонда за период</div>
      </div>
      
      <div className={styles['chart-block']}>

          { loading && (
            <>

              <div className={styles['menu']}>
                <div>
                  <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={20} height={20} />
                </div>
                <div>
                  <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={20} height={20} />
                </div>
                <div>
                  <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={20} height={20} />
                </div>
                <div>
                  <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={20} height={20} />
                </div>
              </div>

              <div className={styles['char-data']}> 

                <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="100%" height={300} />

                <div className={styles['calculator-profitability']}>

                  <div className={styles['calculator-profitability-title']}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="40%" height={22} /></div>

                  <div className={styles['calculator-flex']}>

                    <div className={styles['calculator-flex-left']}>
                      <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="95%" height={50} />
                    </div>

                    <div className={styles['calculator-flex-center']}>
                      <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="95%" height={50} />
                    </div>

                    <div className={styles['calculator-flex-right']}>
                      <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width="95%" height={50} />
                    </div>

                  </div>
                </div>

              </div>
            
            </>
          )}


          { loading == false && (
            <>

              <div className={styles['menu']}>
                <div onClick={() => setStep(1)} className={styles[step == 1 ? 'active' : 'not_active']}>1D</div>
                <div onClick={() => setStep(2)} className={styles[step == 2 ? 'active' : 'not_active']}>1W</div>
                <div onClick={() => setStep(3)} className={styles[step == 3 ? 'active' : 'not_active']}>1M</div>
                <div onClick={() => setStep(4)} className={styles[step == 4 ? 'active' : 'not_active']}>1Y</div>
              </div>

              <div className={styles['char-data']}> 

                { options !== null && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                  />
                ) }

                <div className={styles['calculator-profitability']}>

                  <div className={styles['calculator-profitability-title']}>Калькулятор доходности</div>

                  <div className={styles['calculator-flex']}>

                    <div className={styles['calculator-flex-left']}>
                      <div className={styles['calculator-title']}>Инвестировали</div>
                      <div className={styles['calculator-value']}>
                        <div className={styles['calculator-value-flex']}>
                          <div><input type="number" step="any" className={styles['input']} value={amount} onChange={(e) => setAmountValue(e)}/></div>
                          <div className={styles.currency}>USD</div>
                        </div>
                      </div>
                    </div>

                    <div className={styles['calculator-flex-center']}>
                      <div className={styles['calculator-title']}>{ date }</div>
                      <div className={styles['calculator-value']}>{amount_result} USD</div>
                    </div>

                    <div className={styles['calculator-flex-right']}>
                      <div className={styles['calculator-title']}>Получили</div>
                      <div className={styles['calculator-value']}>{amount_profit} USD</div>
                    </div>

                  </div>
                </div>

              </div>
              
            </>
          )}
          
        
      </div>
    </div>
  );
};
