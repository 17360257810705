import { useState } from 'react';
import { addNewsAdmin } from '../../../http/adminApi';
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ADMIN_NEWS, alertDanger, alertSuccess } from '../../../utils/consts';

import styles from './AdminNews.module.scss';

export const AdminNewsCreate = () => {

  const [ title, setTitle ] = useState('')
  const [ desc, setDesc ] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const createNewsHandler = async () => {
    try {
      await addNewsAdmin(title,desc)
      alertSuccess(dispatch, "Новость успешно добавлена")
      navigate(ADMIN_NEWS)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }


  return (
    <div className={styles.body}>
      <h1 style={{fontSize: 20}}>Добавление новости</h1>

      <div style={{marginTop: 10}}>
        <div>Заголовок новости</div>
        <div style={{marginTop: 5}}>
          <input className={styles.input} type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
        </div>
      </div>
      

      <div style={{marginTop: 10, color: "white"}}>
        <div>Описание</div>
        <div style={{marginTop: 5, backgroundColor: "white", color: "black"}}>
          <textarea className={styles.textarea} value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
        </div>
      </div>

      <div style={{marginTop: 10}}>
        <div>
          <input type="button" onClick={() => createNewsHandler()} value="Добавить" className={styles.button}/>
        </div>
      </div>


    </div>
  );
};
