import { Logo } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ActionButton, Input } from '../../ui-kit/components';
import moment from 'moment'

import styles from './WithdrawalFund.module.scss';
import { chooseFund, getBalanceInvestment, getBalanceWallet, getFundByCpu, getFundsUser, getTotalBalance } from '../../http/fundApi';
import { alertDanger, alertSuccess, DASHBOARD_ROUTER } from '../../utils/consts';
import { actionSetBalance, actionSetBalanceInvestment, actionSetBalanceWallet, actionSetFunds } from '../../store/actionCreators/fundActionCreator';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalFundStep1 = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { cpu } = useParams()

  const [amount, setAmount] = useState()
  const [fund, setFund] = useState({})

  const comission_summ_not_payment = useSelector((state) => state.fund.comission_summ_not_payment)


  const clickBtnChooseFund = async () => {
    try {
      let data = await chooseFund(amount, cpu)

      let total_balance = await getTotalBalance()
      dispatch(actionSetBalance(total_balance.balance))

      let fund_user = await getFundsUser()
      dispatch(actionSetFunds(fund_user))
        
      let balance_wallet = await getBalanceWallet()
      dispatch(actionSetBalanceWallet(balance_wallet.amount))

      let balance_investment = await getBalanceInvestment()
      dispatch(actionSetBalanceInvestment(balance_investment.amount))

      alertSuccess(dispatch,data.message)
      navigate(DASHBOARD_ROUTER)

    } catch (e) {
      alertDanger(dispatch,e.response.data.message)
    }
  }

  const load_data = async () => {
    let fund_data = await getFundByCpu(cpu)
    setFund(fund_data)
  }

  useEffect(() => {
    load_data()
  },[])

  
  let min = moment().format('m')
  let sec = moment().format('s')
  
  let min_will = 60 - Number(min) - 1
  let sec_will = 60 - Number(sec)

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 1/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывести из фонда </h1>
          <h1 className={styles.title}>"{ fund?.name }" </h1>

          <h3 className={styles.under_title}>Ознакомьтесь с условиями управляющего</h3>
        
          <p className={styles.li}>При полном или частичном выводе средств осуществляется досрочный расчет вознаграждения управляющего. На последний расчетный час накопленная комиссия составляет { comission_summ_not_payment } USD, которая будет списана перед выводом.</p>

          <div className={styles.form_footer}>
            <ActionButton onClick={() => navigate(-1)} className={styles.button_cancel}>Отменить</ActionButton>
            <ActionButton onClick={() => navigate('/withdrawal-fund-step-2/' + cpu)} className={styles.button}>Далее</ActionButton>
          </div>

        </div>
      </div>
    </> 
  );
};
