import { $authHost, $host } from './index.js'
import jwt_decode from 'jwt-decode'

export const restorePassword = async (email) => {
    const {data} = await $host.post('api/user/restore-password', {
        email: email
    });
    return data
}

export const restorePasswordData = async (password_new, password_new_confirn, uid) => {
    const {data} = await $host.post('api/user/restore_password_data', {
        password_new, password_new_confirn, uid
    })
    return data
}

export const checkPassRestore = async (uid) => {
    const {data} = await $host.get('api/user/check-pass-restore?uid=' + uid);
    return data
}

export const finishLogin = async (email,password,code) => {

    const {data} = await $host.post('api/user/finish-login', {
        email, password, code
    })

    localStorage.setItem('token',data.token)

    return jwt_decode(data.token)

}

export const google_auth = async () => {
    const {data} = await $authHost.get('api/user/google_auth');
    return data
}

export const getProfile = async () => {
    const {data} = await $authHost.get('api/user/get-profile');
    return data
}

export const saveProfile = async (nickname, about, social_telegram, social_facebook, social_youtube, social_email, social_instagram) => {
    const {data} = await $authHost.post('api/user/save-profile', {
        nickname, 
        about, 
        social_telegram, 
        social_facebook, 
        social_youtube, 
        social_email, 
        social_instagram
    });
    return data
}

export const google_code = async (code) => {
    const {data} = await $authHost.get('api/user/google_code?code='+code);
    localStorage.setItem('token',data.token)
    return jwt_decode(data.token)
}


export const login = async (email,password) => {

    const {data} = await $host.post('api/user/login', {
        email, password
    })
    return data

}

export const reset_2fa = async (email,password) => {

    const {data} = await $host.post('api/user/reset_2fa', {
        email, password
    })
    return data

}

export const change_password = async (password_old, password_new, password_new_confirn) => {
    const {data} = await $authHost.post('api/user/change_password', {
        password_old, password_new, password_new_confirn
    })
    return data
}

export const change_password_final = async (password_old, password_new, password_new_confirn, code) => {
    const {data} = await $authHost.post('api/user/change_password_final', {
        password_old, password_new, password_new_confirn, code
    })
    return data
}



export const register = async (email, password, confirm_password) => {

    const {data} = await $host.post('api/user/register', {
        email, password, confirm_password
    })

    return true
}

export const finishRegister = async (email, password, confirm_password, code) => {

    const {data} = await $host.post('api/user/finish_register', {
        email, password, confirm_password, code
    })

    localStorage.setItem('token',data.token)

    return jwt_decode(data.token)
}


export const checkIsAuth = async () => {

    // Добавляем в запрос сохраненные логин и пароль
    const { data } = await $authHost.post('api/user/auth')
    
    localStorage.setItem('token',data.token)
    
    return jwt_decode(data.token)

}

export const getUserList = async () => {
    const {data} = await $authHost.get('api/user/get_user_list')
    return data
}

export const getBalanceList = async () => {
    const {data} = await $authHost.get('api/user/get_balance_list')
    return data
}

export const setStepDisconnect2FA = async (code) => {
    const {data} = await $authHost.post('api/user/disconnect-2fa',{
        code
    })
    return data
}