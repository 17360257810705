import classNames from 'classnames';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import { Arrow } from '..';
import { useClickOutside } from '../../../hooks';

import styles from './Select.module.scss';

/* 
  options type = {
    value: unknown,
    Component: JSX.Element,
    name: string,
  }[]
*/

export const Select = ({ placeholder, options, onChange, className, selectedOption }) => {
  selectedOption = selectedOption ?? null
  const selectRef = useRef(null);
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(selectedOption);

  useEffect(() => {
    setSelected(selectedOption)
  },[selectedOption])

  const onSelectClick = () => {
    setActive(state => !state);
  };

  const onClickOutside = () => {
    setActive(false);
  };

  const onOptionClick = option => () => {
    setSelected(option);
    onChange(option);
  };

  useClickOutside(onClickOutside, selectRef);

  return (
    <div className={classNames(className, styles.wrapper)} onClick={onSelectClick} ref={selectRef}>
      <div
        className={classNames(styles.selector, {
          [styles['selector--active']]: active,
          [styles['selector--selected']]: selected,
        })}
      >
        {selected ? selected.Component ? <selected.Component /> : selected.name : placeholder}
        <Arrow className={styles.selector__arrow} active={active} color='gray' />
      </div>
      {active && (
        <div className={styles.options}>
          {options?.map(option => (
            <div key={option.value} className={styles.option} onClick={onOptionClick(option)}>
              {option.Component ? <option.Component /> : option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
