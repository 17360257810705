import styles from './Withdrawal.module.scss';
import { Logo } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getWithdrawal, withdrawalStep2 } from '../../http/paymentApi';
import { ActionButton, Input } from '../../ui-kit/components';
import { alertDanger, GOOGLE_AUTH_INFO_ROUTER, GOOGLE_AUTH_ROUTER } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalSecond = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  if (user.google_auth == 0) navigate(GOOGLE_AUTH_INFO_ROUTER)

  const [ code, setCode ] = useState('')
  const [ address_crypto, setAdressCrypto ] = useState('')
  const [ amount_crypto, setAmountCrypto ] = useState('')
  const [ symbol, setSymbol ] = useState('')
  const [ process, setProcess ] = useState(false)

  const { id } = useParams()

  const set2FA = (val) => { 
    setCode(val)
  }

  const check2FAwithdrawal = async () => {
    setProcess(true)
    try {

      let data = await withdrawalStep2(id, code)
      console.log('/withdrawal-step-3/' + data._id)
      navigate('/withdrawal-step-3/' + data._id)
  
    } catch(e) {
      
      alertDanger(dispatch, e.response.data.message)

    }
    setProcess(false)
  }

  const load_data = async () => {

    try {
      let data = await getWithdrawal(id)
      setAdressCrypto(data.address_crypto)
      setAmountCrypto(data.amount_crypto)
      setSymbol(data.symbol)
    } catch(e) {
      navigate('/')
    }

  }

  useEffect(() => {
    load_data()
  }, [])

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 2/3</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывод средств</h1>

          <div className={styles.form}>
            
            <div className={styles.text}>
              Для подтверждения вывода <span style={{color: "white"}}>{ amount_crypto } { symbol }</span> на адрес <span style={{color: "white"}}>{ address_crypto }</span> необходимо в форму ниже ввести 2FA токен из Google-аутентификатора
            </div>

            { process === false && (
              <>

                <div className={styles.input}>
                  { user.stop_check_2fa == 0 && <Input setInputValue={set2FA} label="6-цифр" className={styles.input__2fa} /> }
                </div>

                <div className={styles.input}>
                  { user.stop_check_2fa == 1 && <div>Подтверждение 2FA не требуется, нажмите далее</div> }
                </div>

                <ActionButton onClick={() => check2FAwithdrawal()} className={styles.button}>Далее</ActionButton>
              
              </>
            ) }

            { process && (
              <div style={{textAlign: 'center'}}>Сохранение данных ...</div>
            )}

          </div>

        </div>
      </div>
    </> 
  );
};
