import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFunds } from '../../../http/fundApi';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { FUNDS_ROUTER } from '../../../utils/consts';
import styles from './Recomended.module.scss';
import { useSelector } from 'react-redux';

export const Recomended = (params) => {

  const type = params.type ?? 'small'
  const [funds, setFund] = useState([])
  const rool = useSelector((state) => state.user.rool)

  const load_data = async () => {
    let data = await getFunds(100)
    setFund(data)
  }

  useEffect(() => {
    load_data()
  }, [])

  return (
    <div className={styles.body}>
      <div className={styles.title}>{ type == 'small' ? 'Рекомендуем' : 'Действующие фонды' }</div>

      <div className={styles.container}>
        { funds.length > 0 && funds.map((item) => {

          const data = item?.array_result_year ?? []

          let options = {
            chart: {
              zoomType: 'x',
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              height: 100,
            },
            title: null,
            subtitle: null,
            xAxis: {
              type: 'datetime',
              visible: false,
              labels: {
                enabled: false
              },
              lineWidth: 0,
              minorGridLineWidth: 0,
              lineColor: 'transparent',
              gridLineColor: 'transparent',
              minorTickLength: 0,
              tickLength: 0
            },

            yAxis: {
              title: null,
              visible: false,
              labels: {
                enabled: false
              },
              lineWidth: 0,
              minorGridLineWidth: 0,
              lineColor: 'transparent',
              gridLineColor: 'transparent',
              minorTickLength: 0,
              tickLength: 0
            },
            legend: {
              enabled: false
            },
            plotOptions: {
              area: {
                fillColor: {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                  },
                  stops: [
                    [0, Highcharts.getOptions().colors[0]],
                    [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                  ]
                },
                marker: {
                  radius: 2
                },
                lineWidth: 0,
                lineColor: 'black',
                states: {
                  hover: {
                    lineWidth: 1
                  }
                },
                threshold: null
              }
            },

            series: [{
              type: 'area',
              name: '%',
              data: data
            }]
            
          }
          
          if (
            item._id != "6751f037baa81d9f8c5e2aaa" &&
            item._id != "675c0a494e2a6631895377ff" &&
            item._id != "675c0a954e2a663189537803" &&
            item._id != "675c0ab34e2a663189537804"
              || 
            rool == 'admin' &&  (
              item._id == "6751f037baa81d9f8c5e2aaa" ||
              item._id == "675c0a494e2a6631895377ff" ||
              item._id == "675c0a954e2a663189537803" ||
              item._id == "675c0ab34e2a663189537804"
            )) {

            return (
              <div className={styles.item} key={item._id}>
                <div className={styles['item-header']}>
                  <div className={styles['item-img']}><img src={ process.env.REACT_APP_API_URL + item.logo }/></div>
                  <div className={styles['item-name']}>{ item.name }</div>
                </div>
                <div>
                  <div className={styles['item-graph']}>
                    <div className={styles['item-graph-pr']}><span className={styles.icon}>{ item.geometric_mean_return >= 0 ? ("+ " + item.geometric_mean_return) : ("- " + Math.abs(item.geometric_mean_return)) } %</span></div>
                    { options !== null && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                      />
                    ) }
                  </div>
                </div>
                <div className={styles['item-stat']}>
                  <div className={styles['item-stat-item']}>
                    <div>Баланс</div>
                    <div className={styles['item-stat-item-amount']}>{ item.capital_total }&nbsp;$</div>
                  </div>
                  <div className={styles['item-stat-item']}>
                    <div>Max.просад.</div>
                    <div className={styles['item-stat-item-amount']}>-{ item.max_drawdown } %</div>
                  </div>
                  <div className={styles['item-stat-item']}>
                    <div>Возраст</div>
                    <div className={styles['item-stat-item-amount']}>{ item.duration }</div>
                  </div>
                </div>
                <div className={styles['button-container']}>
                  <Link to={'/choose-fund-step-1/' + item.cpu}>
                    <div className={styles.button}>Инвестировать</div>
                  </Link>
                </div>

                <div className={styles['button-container-more']}>
                  <Link to={'/fund-investments/' + item.cpu}>
                    <div className={styles['button-more']}>Подробнее</div>
                  </Link>
                </div> 
              </div>
            )

          }
        }) }
      </div>

    </div>
  );
};
