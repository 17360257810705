import { actionSetAlertDanger, actionSetAlertDangerText, actionSetAlertSuccess, actionSetAlertSuccessText } from "../store/actionCreators/siteActionCreator"
import { BTC, ETH, UsdIcon } from "../ui-kit/assets"

export const SIGNIN_ROUTER = '/sign-in'
export const MAIN_ROUTER = '/'
export const SIGN_UP_ROUTER = '/sign-up'
export const FINANCE_ROUTER = '/finance'
export const DEPOSIT_FINAL_ROUTER = '/deposit-final/:refill_id'
export const DEPOSIT_ROUTER = '/deposit'
export const HISTORY_ROUTER = '/history'
export const SUPPORT_ROUTER = '/support'
export const CHANGE_PASSWORD_ROUTER = '/change-password'
export const PROFILE_ROUTER = '/profile'
export const PROFILE_SECURITY_ROUTER = '/profile-security'
export const WITHDRAWAL_STEP_1_ROUTER = '/withdrawal-step-1'
export const WITHDRAWAL_STEP_2_ROUTER = '/withdrawal-step-2/:id'
export const WITHDRAWAL_STEP_3_ROUTER = '/withdrawal-step-3/:id'
export const GOOGLE_AUTH_ROUTER = '/google-auth'
export const GOOGLE_AUTH_INFO_ROUTER = '/google-auth-info'
export const DASHBOARD_ROUTER = '/dashboard'
export const INVESTMENTS_ROUTER = '/investments'
export const TRANSACTIONS_ROUTER = '/transactions'
export const TRANSACTIONS_FUND_ROUTER = '/transactions-fund'
export const FUND_ROUTER = '/fund/:cpu'
export const FUND_INVESTMENTS_ROUTER = '/fund-investments/:cpu'
export const FUND_TRADING_ROUTER = '/fund-trading/:cpu'
export const FUNDS_ROUTER = '/funds'
export const CHOOSE_FUND_STEP_1_ROUTER = '/choose-fund-step-1/:cpu'
export const CHOOSE_FUND_STEP_2_ROUTER = '/choose-fund-step-2/:cpu'
export const CHOOSE_FUND_STEP_3_ROUTER = '/choose-fund-step-3/:cpu'
export const WITHDRAWAL_FUND_STEP_1_ROUTER = '/withdrawal-fund-step-1/:cpu'
export const WITHDRAWAL_FUND_STEP_2_ROUTER = '/withdrawal-fund-step-2/:cpu'
export const WITHDRAWAL_FUND_STEP_3_ROUTER = '/withdrawal-fund-step-3/:cpu'
export const RESTORE_PASSWORD_CREATE_ROUTER = '/restore-password-create/:uid'
export const NEWS_ROUTER = '/news'
export const NOTIFICATION_ROUTER = '/notifications'
export const GOOGLE_2FA_RESET = '/google-2fa-reset'
export const RESTORE_PASSWORD_ROUTER = '/password-restore'

// ADMIN
export const ADMIN_SETTING_ROUTER = '/admin-setting'
export const ADMIN_WITHDRAWAL_ROUTER = '/admin-withdrawal'
export const ADMIN_NEWS = '/admin/news'
export const ADMIN_NEWS_UPDATE = '/admin/news/update/:news_id'
export const ADMIN_NEWS_DELETE = '/admin/news/delete/:news_id'
export const ADMIN_NEWS_CREATE = '/admin/news/create'

export const STATUS_PAYMENT = {
    'new': 'Еще не оплачен',
    'sended': 'Оплачен, ожидает подтверждения',
    'received': 'Оплачен, деньги поступили',
    'canceled': 'Отменен',
    'not_received': 'Деньги не поступили',
}



export const alertSuccess = (dispatch,text) => {
    dispatch(actionSetAlertSuccessText(text))
    dispatch(actionSetAlertSuccess(true))
    setTimeout(() => {
        dispatch(actionSetAlertSuccessText(''))
        dispatch(actionSetAlertSuccess(false))
    }, 3000)
}

export const alertDanger = (dispatch,text) => {
    dispatch(actionSetAlertDangerText(text))
    dispatch(actionSetAlertDanger(true))
    setTimeout(() => {
        dispatch(actionSetAlertDangerText(''))
        dispatch(actionSetAlertDanger(false))
    }, 3000)
}

export const prettyUsd = (amount) => {

    if (!isNaN(amount)) {

        let znak = 1
        if (amount < 0) znak = -1
        amount = Math.abs(amount)

        if (amount < 0.0000000000001) {
            amount = Math.round(amount * 1000000000000000) / 1000000000000000
        } else if (amount < 0.000000000001) {
            amount = Math.round(amount * 100000000000000) / 100000000000000
        } else if (amount < 0.00000000001) {
            amount = Math.round(amount * 10000000000000) / 10000000000000
        } else if (amount < 0.0000000001) {
            amount = Math.round(amount * 1000000000000) / 1000000000000
        } else if (amount < 0.000000001) {
            amount = Math.round(amount * 100000000000) / 100000000000
        } else if (amount < 0.00000001) {
            amount = Math.round(amount * 10000000000) / 10000000000
        } else if (amount < 0.0000001) {
            amount = Math.round(amount * 1000000000) / 1000000000
        } else if (amount < 0.000001) {
            amount = Math.round(amount * 100000000) / 100000000
        } else if (amount < 0.00001) {
            amount = Math.round(amount * 10000000) / 10000000
        } else if (amount < 0.0001) {
            amount = Math.round(amount * 1000000) / 1000000
        } else if (amount < 0.001) {
            amount = Math.round(amount * 100000) / 100000
        } else if (amount < 0.01) {
            amount = Math.round(amount * 10000) / 10000
        } else if (amount < 0.1) {
            amount = Math.round(amount * 1000) / 1000
        } else if (amount < 1) {
            amount = Math.round(amount * 100) / 100
        } else if (amount < 10) {
            amount = Math.round(amount * 100) / 100
        } else if (amount < 100) {
            amount = Math.round(amount * 10) / 10
        } else {
            amount = Math.round(amount)
        }

        amount = amount * znak
        
        amount = String(amount)

        if (amount > 999) {
            amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        amount = amount.replace('.',',')

        return amount
    } 

    return ''
}



export const prettyUsdWithSign = (amount) => {

    if (!isNaN(amount)) {

        let znak = 1
        if (amount < 0) znak = -1
        amount = Math.abs(amount)

        if (amount < 0.0000000000001) {
            amount = Math.round(amount * 1000000000000000) / 1000000000000000
        } else if (amount < 0.000000000001) {
            amount = Math.round(amount * 100000000000000) / 100000000000000
        } else if (amount < 0.00000000001) {
            amount = Math.round(amount * 10000000000000) / 10000000000000
        } else if (amount < 0.0000000001) {
            amount = Math.round(amount * 1000000000000) / 1000000000000
        } else if (amount < 0.000000001) {
            amount = Math.round(amount * 100000000000) / 100000000000
        } else if (amount < 0.00000001) {
            amount = Math.round(amount * 10000000000) / 10000000000
        } else if (amount < 0.0000001) {
            amount = Math.round(amount * 1000000000) / 1000000000
        } else if (amount < 0.000001) {
            amount = Math.round(amount * 100000000) / 100000000
        } else if (amount < 0.00001) {
            amount = Math.round(amount * 10000000) / 10000000
        } else if (amount < 0.0001) {
            amount = Math.round(amount * 1000000) / 1000000
        } else if (amount < 0.001) {
            amount = Math.round(amount * 100000) / 100000
        } else if (amount < 0.01) {
            amount = Math.round(amount * 10000) / 10000
        } else if (amount < 0.1) {
            amount = Math.round(amount * 1000) / 1000
        } else if (amount < 1) {
            amount = Math.round(amount * 100) / 100
        } else if (amount < 10) {
            amount = Math.round(amount * 100) / 100
        } else if (amount < 100) {
            amount = Math.round(amount * 10) / 10
        } else {
            amount = Math.round(amount)
        }

        amount = amount * znak
        
        amount = String(amount)

        if (amount > 999) {
            amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        
        if (amount > 0) amount = "+" + String(amount)

        
        amount = amount.replace('.',',')

        return amount
    } 

    return ''
}


export const prettyBtc = (amount) => {
    return amount
}


export const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}




export const options_currency = [
    {
        value: 'BTC', 
        name: 'BTC (Bitcoin)',
        comission: 0.0008,
        image: BTC
    },
    {
        value: 'USDt TRC20', 
        name: 'USDt TRC20 (Tron)',
        comission: 7,
        image: UsdIcon
    },
    {
        value: 'ETH', 
        name: 'ETH (Ethereum)',
        comission: 0.002,
        image: ETH
    },
  ];
