import { Link } from 'react-router-dom';
import { FUNDS_ROUTER, prettyUsd } from '../../../utils/consts';
import { useSelector } from 'react-redux';
import styles from './InvestmentActive.module.scss';
import { Skeleton } from '@mui/material';

export const InvestmentActive = () => {

  const funds = useSelector((state) => state.fund.funds)

  return (
    <div>
      <div className={styles.title}>Инвестиции</div>

      { funds !== null && funds.length == 0 && ( 
        <> 
          <div className={styles.undertitle}>У вас нет активных инвестиций</div> 
        </>
      ) }

      { funds !== null && funds.length > 0 && (
        <>
          <div style={{overflow:'auto', paddingBottom: 20}}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <td>Наименование</td>
                  <td>Инвестиции</td>
                  <td>Динамика 24ч</td>
                  <td>Прибыль</td>
                </tr>
              </thead>
              
              <tbody>
                { funds.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{ item.fund.name }</td>
                      <td>{ prettyUsd(item.investment.amount) } USD</td>
                      <td>{ item.change_24 > 0 ? '+' : '' }{ prettyUsd(item.change_24) } USD</td>
                      <td>{ prettyUsd(item.profit_now) } USD</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      ) }


      { funds == null && (
        <>
          <div style={{overflow:'auto', paddingBottom: 20}}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <td>Наименование</td>
                  <td>Инвестиции</td>
                  <td>Динамика 24ч</td>
                  <td>Прибыль</td>
                </tr>
              </thead>
              
              <tbody>
                <tr>
                  <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                  <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                  <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                  <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) }

      { funds !== null && funds.length == 0 && (
        <>
          <div className={styles['button-container']}>
            <Link to={FUNDS_ROUTER}>
              <div className={styles.button}>Найти фонд</div>
            </Link>
          </div>
        </>
      )}

    </div>
  );
};
