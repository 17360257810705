import styles from './FundDeclaration.module.scss';

import { useEffect, useState } from 'react';
import { IssetDeclaration, NotIssetDeclaration } from '../../../ui-kit/assets';


export const FundDeclaration = (params) => {

  const [maximum_drawdown, setMaximumDrawdown] = useState("")
  const [maximum_day_drawdown, setMaximumDayDrawdown] = useState("")
  const [expected_return, setExpectedReturn] = useState("")

  const [maximum_drawdown_declaration, setMaximumDrawdownDeclaration] = useState("")
  const [maximum_day_drawdown_declaration, setMaximumDayDrawdownDeclaration] = useState("")
  const [expected_return_declaration, setExpectedReturnDeclaration] = useState("")

  const [isset_declaration, setIssetDeclaration] = useState("")

  useEffect(() => {

    if (params.fund) {
        
      setMaximumDrawdown(params.fund.maximum_drawdown)
      setMaximumDayDrawdown(params.fund.maximum_day_drawdown)
      setExpectedReturn(params.fund.expected_return)
      
      setMaximumDrawdownDeclaration(params.fund.maximum_drawdown_declaration)
      setMaximumDayDrawdownDeclaration(params.fund.maximum_drawdown_day_declaration)
      setExpectedReturnDeclaration(params.fund.expected_return_declaration)

      setIssetDeclaration(params.fund.isset_declaration)

    }

  },[params])

  return (
    <div className={styles.body}>

      <div className={styles.title_declaration}>
        <div className={styles.title_declaration_title}>Декларация управляющего</div>
        <div className={styles.title_declaration_img}><img src={isset_declaration == 1 ? IssetDeclaration : NotIssetDeclaration} alt=""/></div>
      </div>
      
      <div className={styles['chart-block']}>
        
          
        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-title']}>Параметры</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-title']}>Фактическое значение</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-title']}>Декларируемое значение</div>
          </div>

        </div>




        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальная просадка</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={[styles[maximum_drawdown <= maximum_drawdown_declaration ? 'green' : 'red']]}>{ maximum_drawdown }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-value']}>{ maximum_drawdown_declaration }%</div>
          </div>

        </div>

        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Максимальная дневная просадка</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={[styles[maximum_day_drawdown <= maximum_day_drawdown_declaration ? 'green' : 'red']]}>{ maximum_day_drawdown }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-value']}>{ maximum_day_drawdown_declaration }%</div>
          </div>

        </div>

        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}>Ожидаемая доходность, мес</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={[styles[expected_return_declaration <= expected_return ? 'green' : 'red']]}>{ expected_return }%</div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left-value']}>{ expected_return_declaration }%</div>
          </div>

        </div>
      </div>
    </div>
  );
};
