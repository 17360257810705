
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderProfile } from '../../components/Profile/HeaderProfile';
import { change_password, change_password_final, getProfile, setStepDisconnect2FA } from '../../http/userAPI';
import { Input, PasswordInput } from '../../ui-kit/components';
import { alertDanger, alertSuccess, GOOGLE_AUTH_ROUTER } from '../../utils/consts';
import styles from './Profile.module.scss';

export const ProfileSecurity = () => {

  const dispatch = useDispatch()
  
  const [ profile, setProfile ] = useState({})
  const [ btn_name, setBtnName ] = useState('Сохранить')
  const [ password, setPassword ] = useState('')
  const [ new_password, setNewPassword] = useState('')
  const [ confirm_new_password, setConfirmNewPassword ] = useState('')
  const [ step_disconnect , setStepDisconnect ] = useState(false)
  const [ code , set2FACode ] = useState('')
  const [ step_password , setStepPassword ] = useState(1)

  const navigate = useNavigate()

  const saveNewPassword = async() => {

    if (step_password === 1) {

      try {
        await change_password(password, new_password, confirm_new_password)
        setBtnName('Подтвердить')
        setStepPassword(2)
      } catch(e) {
        alertDanger(dispatch,e.response.data.message)
      }

    } else if (step_password === 2) {

      setBtnName('Сохранение...')

      try {
        let data = await change_password_final(password, new_password, confirm_new_password, code)
        alertSuccess(dispatch,data.message)
        setBtnName('Сохранено')
        setStepPassword(1)
      } catch(e) {
        alertDanger(dispatch,e.response.data.message)
        setBtnName('Ошибка сохранения')
      }

      setTimeout(() => {
        setBtnName('Сохранить')
      },1000)

    }

  }
  
  const load_data = async() => {
    let data = await getProfile()
    setProfile(data)
  }

  useEffect(() => {
    load_data()
  },[])

  const connect2FA = async () => {
    navigate(GOOGLE_AUTH_ROUTER)
  }

  const disconnect2FA = async () => {
    if (step_disconnect) {
      try {
        let data = await setStepDisconnect2FA(code)
        alertSuccess(dispatch,data.message)
        await load_data()
      } catch(e) {
        alertDanger(dispatch,e.response.data.message)
      }
    } else {
      setStepDisconnect(true)
    }
  }



  return (
    <div className={styles.body}>

      <HeaderProfile/>

      <div className={styles.profile}>

        <div className={styles['item-profile']}>
          <div className={styles['item-profile-title']}>Двухфакторная аутентификация</div>

          <div style={{fontSize: 14, marginTop: 40, color: 'rgb(111,111,111)'}}>Тип</div>
          <div style={{marginTop: 5, fontSize: 16}}>Google Authentificator</div>

          <div style={{marginTop: 40}}>
            { profile.isset_2fa == 0 ? (
              <div className={styles.button} onClick={connect2FA}>Подключить</div> 
            ) : (
              <>
                { step_disconnect ? (
                  <>
                    <div className={styles['field-title']}>6-ти значный код из Google Auth</div>
                    <Input setInputValue={set2FACode} label="6 цифр" inputClassName={styles.input} className={styles['input-container']} />
                    <div className={styles.button_profile} onClick={disconnect2FA}>Отключить</div> 
                  </>
                ) : (
                  <>
                    <div className={styles.button_profile} onClick={disconnect2FA}>Отключить</div> 
                  </>
                )}
              </>
            )}
          </div>

        </div>

        <div className={styles['item-profile']}>
          <div className={styles['item-profile-title']}>Пароль</div>
          
          { step_password === 1 && (
            <>
              <div className={styles['field-title']}>Текущий пароль</div>
              <div>
                <Input type="password" setInputValue={setPassword} inputClassName={styles.input} className={styles['input-container']} />
              </div>

              <div className={styles['field-title']}>Новый пароль</div>
              <div>
                <Input type="password" setInputValue={setNewPassword} inputClassName={styles.input} className={styles['input-container']} />
              </div>

              <div className={styles['field-title']}>Повторите новый пароль</div>
              <div>
                <Input type="password" setInputValue={setConfirmNewPassword} inputClassName={styles.input} className={styles['input-container']} />
              </div>
            </>
          )}

          { step_password === 2 && (
            <>
              <div className={styles['field-title']}>6-ти значный код из Google Auth</div>
              <Input setInputValue={set2FACode} label="6 цифр" inputClassName={styles.input} className={styles['input-container']} />
            </>
          )}
          
          <div className={styles.button_profile} onClick={saveNewPassword}>{ btn_name }</div> 

        </div>

      </div>

    </div>
  );
};