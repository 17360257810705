import { useEffect, useState } from 'react'
import { getNews } from '../../http/siteApi';
import moment from 'moment'
import Parser from 'html-react-parser';

import styles from './News.module.scss';
import { nl2br } from '../../utils/consts';

export const News = () => {

  const [ news, setNews ] = useState([])

  const load_data = async () => {
    let data = await getNews(20)
    setNews(data)
  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Новости</h1>
      { news.map((item) => {
        return (
          <div key={item._id} className={styles.container_item}>
            <div className={styles.title_item}>{ item.title }</div>
            <div className={styles.preview}>{ Parser(nl2br(item.description)) }</div>
            <div className={styles.created_on}>{ moment(new Date(item.created_on)).format('DD/MM/YYYY') }</div>
          </div>
        )
      } ) }
    </div>
  );
};
