import {  Graphs, History } from '../../components/Fnance';
import { Refill } from '../../components';
import { useWindowSize } from '../../hooks';

import styles from './Fnance.module.scss';
import { useDispatch } from 'react-redux';
import { getUserBalance, getActivePayments, getPlans, getGraph, getComission } from '../../http/paymentApi';
import { actionSetBalance, actionSetActivePayments, actionSetPlans, actionSetComission, actionSetGraph } from '../../store/actionCreators/paymentActionCreator';
import { useEffect } from 'react';

export const Fnance = () => {

  const { isMobile } = useWindowSize();
  const dispatch = useDispatch()

  const load_data = async () => {

    let data
       
    data = await getUserBalance()
    console.log("getUserBalance")
    console.log(data)
    dispatch(actionSetBalance(data))
    console.log(data)
    
    data = await getActivePayments()
    dispatch(actionSetActivePayments(data))
    console.log(data)
    
    data = await getGraph()
    dispatch(actionSetGraph(data))
    console.log(data)

  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <>
      <div className={styles.body}>
        {!isMobile ? (
          <>
            <div className={styles['left-column']}>
              <Graphs />
            </div>
            <div className={styles['right-column']}>
              <Refill />
              <History />
            </div>
          </>
        ) : (
          <>
            <Graphs />
            <History />
          </>
        )}
      </div>
    </>
  );
};
