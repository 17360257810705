import { 
    ACTION_SET_BALANCE,
    ACTION_SET_ACTIVE_PAYMENTS,
    ACTION_SET_PLANS,
    ACTION_SET_COMISSION,
    ACTION_SET_GRAPH,
} from '../actions/PaymentAction'

export const actionSetBalance = (payload) => {
    return {
        type: ACTION_SET_BALANCE,
        payload: payload
    }
}

export const actionSetActivePayments = (payload) => {
    return {
        type: ACTION_SET_ACTIVE_PAYMENTS,
        payload: payload
    }
}

export const actionSetPlans = (payload) => {
    return {
        type: ACTION_SET_PLANS,
        payload: payload
    }
}

export const actionSetComission = (payload) => {
    return {
        type: ACTION_SET_COMISSION,
        payload: payload
    }
}
export const actionSetGraph = (payload) => {
    return {
        type: ACTION_SET_GRAPH,
        payload: payload
    }
}