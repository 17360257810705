import { useMemo, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserBalance } from '../../http/paymentApi';
import {
  ActionButton,
  Card,
  Checkbox,
  ColoredText,
  Input,
  Table,
} from '../../ui-kit/components';

import styles from './Refill.module.scss';

export const Refill = () => {

  const balance = useSelector((state) => state.payment.balance)
  
  return (
    <Card className={styles.wrapper}>
      <form className={styles['input-box']}>
        <h4 className={styles.title}>Ваш баланс: {balance.total} USD</h4>
        <ActionButton className={styles['input-box__button']} type='link' to='/deposit'>
          Пополнить
        </ActionButton>
      </form>
      <div>

      </div>
    </Card>
  );
};
